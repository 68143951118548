var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "misc-wrapper"
  }, [_c('div', {
    staticClass: "misc-inner p-2 p-sm-3"
  }, [_c('div', {
    staticClass: "w-100 text-center"
  }, [_c('h2', {
    staticClass: "mb-1"
  }, [_vm._v(" Sedang Dalam Perbaikan 🛠 ")]), _vm._m(0), _c('b-img', {
    attrs: {
      "fluid": "",
      "src": _vm.imgUrl,
      "alt": "Under maintenance page"
    }
  })], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "mb-3"
  }, [_vm._v(" Maaf kegiatan anda di aplikasi ini sedikit terganggu, kami sedang memperbaikinya. "), _vm._v(" Harap hubungi admin atau penanggungjawab aplikasi ini, Terimakasih 😊 ")]);
}]

export { render, staticRenderFns }